import Vue from 'vue'
import VueRouter from 'vue-router'
import {isUserLoggedIn} from '@/auth/utils'
import password from '@/router/password'
import users from '@/router/users'
import clients from '@/router/clients'
import acompanhamentos from '@/router/acompanhamentos'
import areas from '@/router/areas'
import atendimentos from '@/router/atendimentos'
import etapas from '@/router/etapas'
import tiposAtendimentos from '@/router/tiposatendimentos'
import releases from '@/router/releases'
import produtos from '@/router/produtos'
import projetos from '@/router/projetos'
import clientes from '@/router/clientes'
import atendimentosComercial from '@/router/atendimentoscomercial'
import anexos from '@/router/anexos'
import atendimentosTarefas from '@/router/atendimentostarefas'
import sprints from '@/router/sprints'
import atendimentosTempos from '@/router/atendimentostempos'
import atendimentosSatisfacao from '@/router/atendimentossatisfacao'
import macros from '@/router/macros'
import modulos from '@/router/modulos'
import sysVisualizacoes from '@/router/sysvisualizacoes'
import sysAuditoria from '@/router/sysauditoria'
import erpCests from '@/router/erpcests'
import erpBeneficios from '@/router/erpbeneficios'
import concorrentes from '@/router/concorrentes'
import sysConfiguracoes from '@/router/sysconfiguracoes'
import erpGtinFotos from '@/router/erpgtinfotos'
import erpGtins from '@/router/erpgtins'
import erpNcms from '@/router/erpncms'
import valorHora from '@/router/valorhora'
import clientesAtualizacoes from '@/router/clientesatualizacoes'
import erpErros from '@/router/erperros'
import estados from '@/router/estados'
import sysMenu from '@/router/sysmenu'
import perfis from '@/router/perfis'
import clientesModulos from '@/router/clientesmodulos'
import municipios from '@/router/municipios'
import erpIcms from '@/router/erpicms'
import regrasComissoes from '@/router/regrascomissoes'
import redes from '@/router/redes'
import arquivos from '@/router/arquivos'
import arquivoshistorico from '@/router/arquivoshistorico'
import dashboards from '@/router/dashboards'
import tokens from '@/router/tokens'
        // :routes-imports://

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginCenter.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),

    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [
        ...routes,
        ...password,
        ...users,
        ...clients,
        ...acompanhamentos,
        ...areas,
        ...atendimentos,
        ...etapas,
        ...tiposAtendimentos,
        ...releases,
        ...produtos,
        ...projetos,
        ...clientes,
        ...atendimentosComercial,
        ...anexos,
        ...atendimentosTarefas,
        ...sprints,
        ...atendimentosTempos,
        ...atendimentosSatisfacao,
        ...macros,
        ...modulos,
        ...sysVisualizacoes,
        ...sysAuditoria,
        ...erpCests,
        ...erpBeneficios,
        ...concorrentes,
        ...sysConfiguracoes,
        ...erpGtinFotos,
        ...erpGtins,
        ...erpNcms,
        ...valorHora,
        ...clientesAtualizacoes,
        ...erpErros,
        ...estados,
        ...sysMenu,
        ...perfis,
        ...clientesModulos,
        ...municipios,
        ...erpIcms,
        ...regrasComissoes,
        ...redes,
        ...arquivos,
        ...arquivoshistorico,
        ...dashboards,
...tokens,
// :routes-unpack://
    ],
})

router.beforeEach(async (to, from, next) => {
    if (to.name === 'page-error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
        || to.name === 'recuperar-senha') {
        return next()
    }
    if (to.name && to.name.length) {
        if (!isUserLoggedIn() && to.name !== 'login') {
            router.push('/')
            return false
        }
    }
    next()
    return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
        document.title = to.meta?.title || 'Boreodesk'
    }
})

export default router
