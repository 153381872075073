const dashboards = [
    {
        path: '/dashboards/geral',
        name: 'dashboard.geral',
        meta: { title: 'Dashboard | Geral' },
        component: () => import('@/views/dashboards/DashboardGeral.vue'),
    },
    {
        path: '/dashboards/suporte',
        name: 'dashboard.suporte',
        meta: { title: 'Dashboard | Suporte' },
        component: () => import('@/views/dashboards/DashboardSuporte.vue'),
    },
    {
        path: '/dashboards/resumo-cliente',
        name: 'dashboard.resumo-cliente',
        meta: { title: 'Dashboard | Clientes' },
        component: () => import('@/views/dashboards/DashboardResumoCliente.vue'),
    },
    {
        path: '/dashboards/produtividade',
        name: 'dashboard.produtividade',
        meta: { title: 'Dashboard | Produtividade' },
        component: () => import('@/views/dashboards/DashboardProdutividade.vue'),
    },
]

export default dashboards
