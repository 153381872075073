const atendimentos = [
  {
    path: '/atendimentos',
    name: 'atendimentos.list',
    meta: { title: 'Atendimentos' },
    component: () => import('@/views/atendimentos/AtendimentosHome.vue'),
  },
  {
    path: '/atendimentos/:id',
    name: 'atendimentos.show',
    component: () => import('@/views/atendimentos/AtendimentoDetail.vue'),
  },
  {
    path: '/pesquisar-comentarios',
    name: 'pesquisa.comentarios',
    meta: { title: 'Pesquisa de Comentários' },
    component: () => import('@/views/atendimentos/PesquisaComentarios.vue'),
  },
  {
    path: '/pesquisar-atendimentos',
    name: 'pesquisa.atendimento',
    meta: { title: 'Pesquisa de Atendimentos' },
    component: () => import('@/views/atendimentos/PesquisaAtendimentos.vue'),
  },
]

export default atendimentos
